import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading } from 'theme-ui'

export const FooterCto = () => {

    const styles = {
        flexLayout: {
            flexDirection: 'column',
            alignItems: ['center','flex-start'],
            mx: ["10%", 0],
            textAlign: ['center', 'left'],
            textWeigth: '700'
        },
    }
   
    return (
            <Flex sx={styles.flexLayout}>
                <Heading as="h2" variant="spacedH2">Nós ajudamos sua empresa a se conectar com as melhores práticas de gestão do mundo.</Heading>
                <Button as={Link} to='https://xtree.com.vc/'  pl={4} pr={4} variant="secondary">Conheça as nossas soluções</Button>
            </Flex>
    )
}