import common from './common'

export default {
  ...common.badge,
  bg: `omegaLight`,
  color: `tagNewText`,
  fontWeigth: '800',
  ':hover': {
    color: `hoverColor`,
    bg: `hoverBg`
  }
}