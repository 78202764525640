import React from 'react'
import { Container, Divider, Box, Flex } from 'theme-ui'
import { FooterLogo } from './Footer.Logo'
import { FooterMenu } from './Footer.Menu'
import { FooterSocial } from './Footer.Social'
import { FooterCto } from './Footer.Cto'
import { FooterBrands } from './Footer.Brands'


const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
    mt: [5, 6]
  },
  cto: {
    flexBasis: '35%',
  },
  brands: {
    flexBasis: '55%'
  },
  divider: {
    mb: "10px"
  }
}

export const Footer = () => (
  <Box sx={styles.wrapper}>
    <Container variant='compact' sx={styles.container}>
        <Divider sx={styles.divider}></Divider>
        <Flex variant="layout.footerBanner">
        <Box sx={styles.cto}>
          <FooterCto></FooterCto>
        </Box>
        <Box sx={styles.brands}>
          <FooterBrands></FooterBrands>
        </Box>
      </Flex>
      <Divider sx={styles.divider}></Divider>
      <Flex variant='layout.footer'>
        <Box>
          <FooterLogo />
        </Box>
        <FooterMenu />
        <Box>
          <FooterSocial />
        </Box>
      </Flex>
    </Container>
  </Box>
)
