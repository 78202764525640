import common from './common'

export default {
  ...common.button,
  color: `#00190A`,
  bg: `alpha`,
  borderColor: `alpha`,
  fontWeight: '800',
  ':hover': {
    color: `hoverColor`,
    bg: `hoverBg`,
    borderColor: `hoverBg`
  }
}
