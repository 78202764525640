const heading = {
  display: `block`,
  color: `heading`,
  fontWeight: `heading`,
  textDecoration: `none`,
  mb: 3
}

export default {
  heading: {
    ...heading
  },
  h1: {
    ...heading,
    fontSize: [5, 6, null, 7],
    fontWeight: `bolder`
  },
  h2: {
    ...heading,
    fontSize: 4,
    paddingBottom: 1
  },
  h3: {
    ...heading,
    fontSize: 3,
    paddingBottom: 1
  },
  h4: {
    ...heading,
    fontSize: 2
  },
  h4Black: {
    ...heading,
    fontSize: 2,
    color: '#00190A'
  },
  h5: {
    ...heading,
    fontSize: 1
  },
  medium: {
    fontSize: 3,
    mb: 3
  },
  small: {
    fontSize: 1,
    m: 0
  },
  xsmall: {
    fontSize: 0,
    m: 0
  },
  p: {
    fontFamily: `body`,
    mb: 3
  },
  spacedH2: {
    ...heading,
    fontSize: "30px",
    paddingBottom: 1,
    lineHeight: '39px',
    mb: '35px',
    fontWeight: '700'
  },
  normalH4: {
    ...heading,
    fontSize: "20px",
    fontWeight: 400
  }
}
