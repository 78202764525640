import React from 'react'
import { Box, Heading, Image, Flex } from 'theme-ui'
import logoModal from './logos/logo-modal-black.png'
import logoSap from './logos/logo-sap-black.png'
import logoGafisa from './logos/logo-gafisa-black.png'
import logoStone from './logos/logo-stone-black.png'
import logoTrigo from './logos/logo-trigo-black.png'
import logoXp from './logos/logo-xp-inc-black2.png'


export const FooterBrands = () => {

    const styles = {
        brandsBox: {
            background: "#FFFFFF",
            px: '40px',
            pt: '21px',
            pb: '35px',
            minHeight: '258px',
            borderStyle: `solid`,
            borderRadius: "19px",
            borderColor: `#0BD85E`,
            borderWidth: 'sm',
            borderLeftWidth: '5px',
        },
        brandsGrid: {
            flexDirection: ['column', 'row'],
            flexWrap: 'wrap',
            gap: '45px 30px',
            justifyContent: 'space-between',
            alignItems: 'center',
        }

    }
   
    return (
          <Box sx={styles.brandsBox}>
              <Heading mb={"28px"} as="h3" variant="normalH4" color="#00190A">Alguns de nossos clientes:</Heading>
              <Flex sx={styles.brandsGrid}>
                <Image sx={{width: 129, height: 34}} src={logoXp}></Image>
                <Image sx={{width: 129, height: 34}} src={logoGafisa}></Image>
                <Image sx={{width: 87, height: 45, mr: "30px"}} src={logoSap}></Image>
                <Image sx={{width: 94, height: 25}} src={logoStone}></Image>
                <Image sx={{width: 125, height: 40}} src={logoTrigo}></Image>
                <Image sx={{width: 129, height: 38, mb: "5px"}} src={logoModal}></Image>
              </Flex>
          </Box>
    )
}