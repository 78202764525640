const white = '#fff'
const gray = {
  200: '#edf2f7',
  300: '#e2e8f0',
  400: '#cbd5e0',
  500: '#a0aec0',
  600: '#718096',
  700: '#4a5568',
  800: '#2d3748',
  900: '#1a202c'
}
const indigo = {
  100: '#ebf4ff',
  300: '#a3bffa',
  500: '#667eea',
  600: '#5a67d8',
  800: '#434190'
}
const purple = {
  100: '#faf5ff',
  300: '#d6bcfa',
  500: '#9f7aea',
  600: '#805ad5',
  800: '#553c9a'
}
const green = {
  100: '#f0fff4',
  200: '#0BD85E',
  300: '#13cc54',
  500: '#06a940',
  600: '#038b33',
  800: '#007228'
}
const red = {
  100: '#fff5f5',
  500: '#f56565'
}

const nightGreen = "#00190A"

//Primary
const alpha = {
  alphaLighter: green[100],
  alphaLight: green[300],
  alpha: green[200],
  alphaDark: green[600],
  alphaDarker: green[800]
}

// Secondary
const beta = {
  betaLighter: green[100],
  betaLight: green[300],
  beta: green[500],
  betaDark: green[600],
  betaDarker: green[800]
}

// Reserved
const gamma = {}

// Reserved
const psi = {}

// Neutral
const omega = {
  omegaLighter: gray[900],
  omegaLight: green[200],
  omega: green[100],
  omegaDark: green[100],
  darkOmega: '#000000',
  omegaDarker: green[100]
}

const xtree = {
    background: nightGreen,
    backgroundLight: '#FFFFFF',
    contents: '#052b24',
    separadorheader: '#03491F',
}

export default {
  text: white,
  article: green[100],
  heading: white,

  ...alpha,
  ...beta,
  ...gamma,
  ...psi,
  ...omega,

  readTime: '#08A849',
  successLight: green[100],
  success: green[500],
  errorLight: red[100],
  error: red[500],
  articleBorder: '#F9F9F9',

  tagNew: '#0BD85E',
  tagNewText: '#00190A',
  categoriesBg: '#F9F9F9',
  categoriesText: nightGreen,
  bannerBorder: '#F9F9F9',

  hoverBg: '#f0fff4',
  hoverColor: '#00190A',

  white: white,
  background: xtree['background'],
  contentBg: xtree['contents'],
  headerBg: xtree['background'],
  footerBg: xtree['background'],
  headerBorder: '#03491F',
  footerText: '#FFFFFF',
  cardsBg: nightGreen,

  constraste: green[100],

  mute: green[300],
  highlight: green[200],

  modes: {
    dark: {
      // Reverse alpha
      articleBorder: '#0BD85E',
      alphaLighter: gray[900],
      alphaLight: alpha.alphaDark,
      alphaDark: gray[900],
      alphaDarker: gray[900],

      // Reverse beta
      betaLighter: gray[900],
      betaLight: gray[900],
      betaDark: gray[900],
      betaDarker: gray[200],

      // Reverse omega
      omegaLighter: omega.omegaDarker,
      omegaLight: green[200],
      omegaDark: gray[700],
      omegaDarker: gray[200],
      omega: gray[800],

      successLight: gray[700],

      text: gray[700],
      article: gray[800],
      heading: gray[900],

      hoverBg: '#00190A',
      hoverColor: '#0BD85E',

      constraste: gray[900],

      cardsBg: '#FFFFFF',
      background: '#F9F9F9',
      contentBg: white,
      headerBg: xtree.backgroundLight,
      footerBg: '#F9F9F9',
      footerText: '#00190A',
      headerBorder: '#DBDBDB',
      categoriesBg: '#FFFFFF',
      mute: gray[600]
    }
  }
}