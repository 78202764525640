import common from './common'

export default {
    ...common.button,
    color: `#00190a`,
    bg: `#c7f300`,
    borderColor: `#c7f300`,
    ':hover': {
      color: `#00190a`,
      bg: `#FFFFFF`,
      borderColor: `#FFFFFF`
    }
  }
  
